import { render, staticRenderFns } from "./TwoFactorAuthenticationSetup.vue?vue&type=template&id=de9d7c06&scoped=true"
import script from "./TwoFactorAuthenticationSetup.vue?vue&type=script&lang=js"
export * from "./TwoFactorAuthenticationSetup.vue?vue&type=script&lang=js"
import style0 from "./TwoFactorAuthenticationSetup.vue?vue&type=style&index=0&id=de9d7c06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de9d7c06",
  null
  
)

export default component.exports